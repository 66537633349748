import React from 'react';
import {Row, Col} from 'antd';

import styles from './information.module.scss';
import img1 from './img_1.jpg';
import img2 from './img_2.png';

const EventInfo: React.FC<{giftsCount: number}> = ({giftsCount}) => {
    return (
        <>
            <Row>
                <div className={styles.title}>Втілені у життя мрії</div>
            </Row>
            <Row>
                <Col lg={12}>
                    <Row>
                        <div className={styles.image1Container}>
                            <img className={styles.img1} src={img1} alt="img1" />
                            <div className={styles.img1Border}></div>
                        </div>
                    </Row>
                </Col>
                <Col lg={12}>
                    <Row className={styles.text}>
                        Нижче ви можете побачити фото <strong>{giftsCount}</strong> дітей, яких вдалося зробити
                        щасливими&nbsp;у&nbsp;2021&nbsp;році. Їх радість — завжди найщиріша, а посмішки —
                        найширші!&nbsp;Завдяки&nbsp;кожному,&nbsp;хто здійснив маленьку мрію — купив іграшку,
                        фарби&nbsp;чи&nbsp;омріяну гру — діти продовжують зберігати у своїх серцях віру у найголовніше.
                        Віру в диво.
                    </Row>
                    <Row>
                        <div className={styles.image2Container}>
                            <img className={styles.img2} src={img2} alt="img2" />
                            <div className={styles.img2Border}></div>
                        </div>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default EventInfo;
