import React from 'react';
import {Col, Row} from 'antd';

import girl from 'images/photo/girl.png';

import styles from './stockInformation.module.scss';

const CharityEventDescription: React.FC = () => {
    return (
        <>
            <Col lg={12}>
                <h2 className={styles.title}>
                    Благодійна акція <br /> "Стань Чарівником"
                </h2>
                <Row className={styles.block}>
                    <Col lg={14} xs={24}>
                        <iframe
                            height="490"
                            src="https://www.youtube.com/embed/wTgIlEGQi1Y"
                            title="Доброта та довіра"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            className={styles.video}
                        />
                    </Col>
                    <Col lg={10} xs={18} offset={2}>
                        <div className={styles.information_card}>
                            <p>
                                Благодійна акція “Стань Чарівником“, організована фондом “Доброта та Довіра“, є чудовою
                                можливістю допомогти особливим дітям, які вірять у диво та добро. Ця акція об’єднує
                                людей, які бажають зробити день Святого Миколая незабутнім для цих дітей, незважаючи на
                                їхні життєві труднощі.
                            </p>

                            <p>
                                Нижче викладені фотографії листів дітлахів, які вірять у диво та добро. Діти з різними
                                життєвими історіями: сироти, діти з інвалідністю, онкохворі, але їх поєднує віра. Віра в
                                чаклунство! Запрошуємо стати для когось феєю чи чарівником!
                            </p>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col lg={10} xs={22} offset={2}>
                <img className={styles.photo} src={girl} alt="girl" />
            </Col>
        </>
    );
};

export default CharityEventDescription;
