import React from 'react';

import BreadCrumbs from './components/BreadCrumbs';
import styles from './requisites.module.scss';

const Requisites: React.FC = () => {
    return (
        <div className={styles.item}>
            <BreadCrumbs />
            <div className={styles.main}>
                <div className={styles.heading}>Реквізити фонду</div>
                <div className="requisites-privat">
                    <h3>
                        <strong>ПриватБанк</strong>
                    </h3>
                    <div className="requisites-privat__card">
                        <strong>5363 5420 1719 9317</strong>
                    </div>
                    <p>(Карта оформлена на Ткаченко Валентину Валентинівну, засновницю фонду)</p>
                </div>

                <div className="requisites-privat">
                    <h3>
                        <strong>Монобанк</strong>
                    </h3>
                    <div className="requisites-privat__card">
                        <strong>5375 4141 3740 7036</strong>
                    </div>
                    <p>(Карта оформлена на Ткаченко Валентину Валентинівну, засновницю фонду)</p>
                </div>
            </div>
        </div>
    );
};

export default Requisites;
