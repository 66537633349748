import React from 'react';
import {Field, Formik} from 'formik';
import {Button, Col, Form as AntdForm, Row} from 'antd';

import * as Yup from 'yup';

import FormTextInput from 'components/form/TextInput';
import FormTextArea from 'components/form/TextArea';

export interface FormValues {
    name: string | undefined;
    phone: string | undefined;
    comment: string | undefined;
}

interface FormProps {
    onClose(): void;
    onSubmit(fields: FormValues): void;
}

const phoneMask = [
    '+',
    '3',
    '8',
    ' ',
    '(',
    /[0-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
];

const requiredFieldMessage = "Обов'язкове поле";

const validationSchema = Yup.object().shape<FormValues>({
    name: Yup.string().required(requiredFieldMessage),
    phone: Yup.string().required(requiredFieldMessage),
    comment: Yup.string().required(requiredFieldMessage),
});

const FormContent = ({formProps: {handleSubmit}, onClose}: any): JSX.Element => {
    return (
        <AntdForm onSubmit={handleSubmit} className="form">
            <div className="form__main-block">
                <Field
                    maxLength={50}
                    label="Ваше Прізвище Ім'я"
                    name="name"
                    component={FormTextInput}
                    required={true}
                    placeholder=""
                />
                <Field
                    maxLength={50}
                    name="phone"
                    label="Телефон"
                    mask={phoneMask}
                    component={FormTextInput}
                    placeholder="+38 (___) ___ __ __"
                />
                <Field
                    label="Посилання на профіль у соцмережах"
                    name="comment"
                    component={FormTextArea}
                    required={true}
                />
                <p>
                    До основного подарунка за бажанням можна додати солодощі на ваш розсуд. Діти дуже люблять солодке та
                    раді всьому.
                </p>
            </div>
            <div className="form__info-block">
                <h3>Надсилання подарунка Новою Поштою</h3>
                <div className="form__info-block_address">
                    <Row>
                        <Col md={6}>Адреса</Col>
                        <Col md={16}>м. Миколаїв, відділення №6</Col>
                    </Row>
                    <Row>
                        <Col md={6}>Одержувач</Col>
                        <Col md={16}>
                            Ткаченко Валентина Валентинівна
                            <br />
                            тел. +38 (097) 239 72 70
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>Коментар</Col>
                        <Col md={16}>
                            <em>вказати Прізвище та Ім'я дитини, якій робите подарунок</em>
                        </Col>
                    </Row>
                </div>
                <div className="form__info-block_request">
                    Якщо ви надсилаєте посилку Новою Поштою, просимо оплатити послуги за пересилку.
                </div>
            </div>
            <div className="form__footer-block">
                <Row type="flex" justify="space-between">
                    <Button onClick={onClose} className="custom-btn custom-btn__cancel">
                        Скасувати
                    </Button>
                    <Button type="primary" htmlType="submit" className="custom-btn custom-btn__action">
                        Забронювати
                    </Button>
                </Row>
            </div>
        </AntdForm>
    );
};

const Form = ({onSubmit, onClose}: FormProps): JSX.Element => (
    <Formik
        initialValues={{name: '', phone: '', socialLink: '', comment: ''}}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
    >
        {props => <FormContent formProps={props} onClose={onClose} />}
    </Formik>
);

export default Form;
